import { PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Layout from '../layouts/Layout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrorCode = styled.h1``;

const NotFoundPage: React.FC<PageProps> = function () {
  return (
    <Layout>
      <Container>
        <ErrorCode>404 Not Found</ErrorCode>
        <h3>Uh oh!</h3>
        <span>The page you are looking for could not be found.</span>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
